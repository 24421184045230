@container ud-content-area (min-width: @content-area-sm-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) * (3 / 4) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-md-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (6 / 12) + (6 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (4 / 12) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (4 / 9) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-mid) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (3 / 9) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-xl-min) {
  grid-auto-columns: calc((100% - (5 - 1) * 1.6rem) / 5);
}.top-skill {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 31.1rem;
  min-width: 22.1rem;
}
.top-skill .title {
  font-family: var(--font-stack-heading);
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0;
  font-size: clamp(2rem, calc(2rem + (3.2 - 2) * calc((100vw - 36rem) / (144 - 36))), 3.2rem);
  max-width: 36em;
}
.top-skill .cta {
  font-size: clamp(1.65rem, calc(1.65rem + (1.8 - 1.65) * calc((100vw - 36rem) / (144 - 36))), 1.8rem);
  margin-top: 1.6rem;
}
.top-skill .stat {
  font-family: var(--font-stack-text);
  font-weight: 400;
  line-height: 1.4;
  font-size: 1.4rem;
  margin-top: 0.8rem;
  color: #9194ac;
}
@media (max-width: 61.25rem) {
  .top-skill {
    max-width: 22.1rem;
  }
}
@media (max-width: 43.75rem) {
  .top-skill {
    width: 100%;
    max-width: 100%;
  }
}
.skeleton,
.parent-skeleton {
  width: 100%;
}
.skeleton {
  min-width: 134rem;
  height: 20.9rem;
}
@media (max-width: 61.25rem) {
  .skeleton {
    height: 18.8rem;
  }
}
@media (max-width: 43.75rem) {
  .skeleton {
    height: 11.3rem;
  }
}
@media (max-width: 23.4375rem) {
  .skeleton {
    height: 10.7rem;
  }
}
