@container ud-content-area (min-width: @content-area-sm-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) * (3 / 4) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-md-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (6 / 12) + (6 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (4 / 12) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (4 / 9) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-mid) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (3 / 9) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-xl-min) {
  grid-auto-columns: calc((100% - (5 - 1) * 1.6rem) / 5);
}.card {
  background-color: var(--color-white);
  display: flex;
  border-radius: 8px;
  border: 1px solid #d1d2e0;
  padding-left: 2.4rem;
  flex-direction: row;
  height: 100%;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.description {
  color: #595c73;
}
.card:hover {
  border: 1px solid #d1d2e0;
  padding-left: 2.4rem;
  background-color: var(--color-gray-150);
}
.card:hover .description {
  color: #303141;
}
@container (inline-size < unit(531 * @px-to-rem, rem)) {
  .card {
    min-height: 12.8rem;
  }
}
.content {
  padding: 0.8rem 1.6rem 0;
}
.title-flex {
  display: flex;
  margin-bottom: 0.8rem;
}
.title {
  border: none;
  background: transparent;
  padding: 0;
  font-family: var(--font-stack-heading);
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0;
  font-size: clamp(1.65rem, calc(1.65rem + (1.8 - 1.65) * calc((100vw - 36rem) / (144 - 36))), 1.8rem);
  max-width: 36em;
}
@media (min-width: 43.8125rem) and (max-width: 61.25rem) {
  .title {
    font-family: var(--font-stack-heading);
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0;
    font-size: 1.6rem;
  }
}
.selected {
  border-color: var(--color-purple-300);
  border-left-width: 0.8rem;
  padding-left: 1.7rem;
}
.enterprise-badge {
  border: 1px solid var(--color-blue-300);
  background-color: var(--color-gray-100);
  color: var(--color-blue-300);
  padding: 0.3rem 0.8rem;
  border-radius: 4px;
  margin-left: 1.6rem;
  vertical-align: text-bottom;
  max-height: 2.2rem;
}
/** Custom breakpoint size */
@media (max-width: 53.13em) {
  .icon {
    display: none;
  }
}
