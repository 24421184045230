@container ud-content-area (min-width: @content-area-sm-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) * (3 / 4) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-md-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (6 / 12) + (6 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (4 / 12) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (4 / 9) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-mid) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (3 / 9) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-xl-min) {
  grid-auto-columns: calc((100% - (5 - 1) * 1.6rem) / 5);
}.button {
  width: 100%;
}
.content-container {
  width: 100%;
}
.content-container > * + * {
  margin-top: 2.4rem;
}
.image {
  aspect-ratio: 2;
  width: 100%;
}
.image-container {
  width: 100%;
}
.image-square {
  aspect-ratio: 1;
  object-fit: cover;
  width: 100%;
}
.logo-lg {
  display: none;
}
.logo-sm {
  display: block;
  padding-bottom: 2.4rem;
}
.logo-sm img {
  height: 3.2rem;
  width: auto;
}
.stat {
  border-bottom: 1px solid #d1d2e0;
  flex-basis: 100%;
  padding-bottom: 0.8rem;
}
.stats-container {
  display: flex;
}
.stats-container > * + * {
  margin-left: 1.6rem;
}
.stat-label {
  font-family: var(--font-stack-text);
  font-weight: 400;
  line-height: 1.4;
  font-size: 1.2rem;
}
.stat-value {
  font-family: var(--font-stack-heading);
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0;
  font-size: clamp(2.2rem, calc(2.2rem + (4 - 2.2) * calc((100vw - 36rem) / (144 - 36))), 4rem);
  max-width: 36em;
}
.heading {
  margin-bottom: 2.4rem;
  font-family: var(--font-stack-heading);
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0;
  font-size: clamp(2rem, calc(2rem + (3.2 - 2) * calc((100vw - 36rem) / (144 - 36))), 3.2rem);
  max-width: 36em;
}
.wrapper {
  display: flex;
  flex-direction: column-reverse;
}
@media (min-width: 37.5625rem) {
  .stat-label {
    font-family: var(--font-stack-text);
    font-weight: 400;
    line-height: 1.4;
    font-size: 1.4rem;
  }
}
@media (min-width: 61.3125rem) {
  .button {
    width: auto;
  }
  .content-container {
    padding-top: 4.8rem;
    padding-right: 1.6rem;
  }
  .image {
    aspect-ratio: unset;
  }
  .image-container {
    flex: 0 0 auto;
    width: 50%;
  }
  .logo-lg {
    display: block;
    max-height: 4.8rem;
  }
  .logo-sm {
    display: none;
  }
  .stat {
    padding-bottom: 2.4rem;
  }
  .wrapper {
    flex-direction: row;
  }
}
@media (min-width: 75.0625rem) {
  .content-container {
    padding-top: 6.4rem;
    padding-right: 9.2rem;
  }
  .content-container > * + * {
    margin-top: 3.2rem;
  }
  .stat-label {
    font-family: var(--font-stack-text);
    font-weight: 400;
    line-height: 1.4;
    font-size: 1.6rem;
  }
}
@media (min-width: 61.3125rem) and (max-width: 75rem) {
  .content-container > * + * {
    margin-top: 1.6rem;
  }
  .logo-lg {
    display: block;
    padding-bottom: 2.4rem;
    padding-bottom: 0;
  }
  .logo-lg img {
    height: 3.2rem;
    width: auto;
  }
  .stat {
    padding-bottom: 1.6rem;
  }
  .heading {
    margin-bottom: 1.6rem;
  }
}
